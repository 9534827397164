<template>
  <div>
    <div class="header" style="margin-bottom: 20px">
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">权限配置</span>
      </div>
    </div>
    <div style="background-color:#fff;">
      <transition>
        <router-view :key="key"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
      key: 0
    };
  },
  mounted () {
    this.key = new Date().getTime()
    console.log(this.key, 8956);
  },

  methods: {
  }
};
</script>

<style>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>
<style lang="scss" scoped>
.margin-left-8 {
  margin-left: 8px;
}
</style>
